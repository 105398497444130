var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"demo-spin-article"},[_c('div',{staticClass:"mb-3"},[_c('label',{staticClass:"col-label form-label required tw-text-xs"},[_vm._v(_vm._s(_vm.$t('basicSalary.employee'))+" ")]),_c('Select',{class:{
                    'is-invalid': _vm.errors.has('employee_id')
                },attrs:{"filterable":"","remote-method":_vm.loadEmployee,"clearable":true,"disabled":_vm.isUpdate},model:{value:(_vm.model.employee_id),callback:function ($$v) {_vm.$set(_vm.model, "employee_id", $$v)},expression:"model.employee_id"}},_vm._l((_vm.employeeProfiles),function(employeeProfile,index){return _c('Option',{key:index,attrs:{"value":employeeProfile.employee_id}},[_vm._v(_vm._s(employeeProfile.employee_name_en)+"|"+_vm._s(employeeProfile.employee_name_kh)+" ")])}),1),(_vm.errors.has('employee_id'))?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.errors.first('employee_id'))+" ")]):_vm._e()],1),_c('div',{staticClass:"row mb-3"},[_c('label',{staticClass:"col-label form-label required tw-text-xs"},[_vm._v(_vm._s(_vm.$t('basicSalary.salary')))]),_c('div',{staticClass:"tw-whitespace-nowrap"},[_c('Poptip',{attrs:{"trigger":"focus"}},[_c('Input',{ref:"salary",class:{
                            'is-invalid': _vm.errors.has('salary')
                        },staticStyle:{"width":"270%"},attrs:{"type":"number","placeholder":_vm.$t('basicSalary.enterNumber')},model:{value:(_vm.model.salary),callback:function ($$v) {_vm.$set(_vm.model, "salary", _vm._n($$v))},expression:"model.salary"}}),(_vm.errors.has('salary'))?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.errors.first('salary'))+" ")]):_vm._e(),_c('div',{attrs:{"slot":"content"},slot:"content"},[_vm._v(" "+_vm._s(_vm.model.salary ? _vm.formatNumber(_vm.model.salary) : _vm.$t('basicSalary.enterNumber'))+" ")])],1)],1),(_vm.errors.has('salary'))?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.errors.first('salary'))+" ")]):_vm._e()]),_c('div',{staticClass:"row mb-3"},[_c('label',{staticClass:"col-label form-label  tw-text-xs"},[_vm._v(_vm._s(_vm.$t('basicSalary.salaryAfterProbation')))]),_c('div',{staticClass:"tw-whitespace-nowrap"},[_c('Poptip',{attrs:{"trigger":"focus"}},[_c('Input',{ref:"salary_after_probation",class:{
                            'is-invalid': _vm.errors.has(
                                'salary_after_probation'
                            )
                        },staticStyle:{"width":"270%"},attrs:{"type":"number","placeholder":_vm.$t('basicSalary.enterNumber')},model:{value:(_vm.model.salary_after_probation),callback:function ($$v) {_vm.$set(_vm.model, "salary_after_probation", _vm._n($$v))},expression:"model.salary_after_probation"}}),(_vm.errors.has('salary_after_probation'))?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.errors.first('salary_after_probation'))+" ")]):_vm._e(),_c('div',{attrs:{"slot":"content"},slot:"content"},[_vm._v(" "+_vm._s(_vm.model.salary_after_probation ? _vm.formatNumber(_vm.model.salary_after_probation) : _vm.$t('basicSalary.enterNumber'))+" ")])],1)],1)]),_c('div',{staticClass:"row mb-3"},[_c('label',{staticClass:"col-label form-label required tw-text-xs"},[_vm._v(_vm._s(_vm.$t('basicSalary.effectiveDate')))]),_c('div',[_c('date-Picker',{class:{
                        'is-invalid': _vm.errors.has('effective_date')
                    },staticStyle:{"width":"487px"},attrs:{"value":_vm.model.effective_date,"type":"date","format":"dd-MM-yyyy","placeholder":"Select date"},on:{"on-change":function (date) { return (_vm.model.effective_date = date); }}}),(_vm.errors.has('effective_date'))?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.errors.first('effective_date'))+" ")]):_vm._e()],1)]),_c('div',{staticClass:"row"},[_c('Checkbox',{model:{value:(_vm.model.payroll_visible),callback:function ($$v) {_vm.$set(_vm.model, "payroll_visible", $$v)},expression:"model.payroll_visible"}},[_vm._v(_vm._s(_vm.$t('basicSalary.payrollVisible')))])],1),_c('div',{attrs:{"slot":"footer"},slot:"footer"},[_c('div',{staticClass:"tw-flex tw-justify-end tw-space-x-2 tw-sh mt-3"},[_c('ts-button',{staticClass:"btn-gray",on:{"click":function($event){$event.preventDefault();return (function () { return _vm.$emit('cancel'); }).apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t('cancel')))]),(!_vm.isUpdate)?_c('ts-button',{attrs:{"color":"primary","outline":"","waiting":_vm.waiting_new,"disabled":_vm.waiting_new || _vm.waiting},on:{"click":function($event){$event.preventDefault();return _vm.onSaveAddNew.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t('saveAddNew')))]):_vm._e(),(!_vm.isUpdate)?_c('ts-button',{attrs:{"color":"primary","waiting":_vm.waiting,"disabled":_vm.waiting_new || _vm.waiting},on:{"click":function($event){$event.preventDefault();return _vm.onSave.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t('save')))]):_vm._e(),(_vm.isUpdate)?_c('ts-button',{attrs:{"color":"primary","waiting":_vm.waiting},on:{"click":function($event){$event.preventDefault();return _vm.onUpdate.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t('update')))]):_vm._e()],1)])]),(_vm.loading)?_c('Spin',{attrs:{"fix":""}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }