<template>
    <div>
        <div class="demo-spin-article">
            <div class="mb-3">
                <label class="col-label form-label required tw-text-xs"
                    >{{ $t('basicSalary.employee') }}
                </label>
                <Select
                    v-model="model.employee_id"
                    filterable
                    :remote-method="loadEmployee"
                    :clearable="true"
                    :disabled="isUpdate"
                    :class="{
                        'is-invalid': errors.has('employee_id')
                    }"
                >
                    <Option
                        v-for="(employeeProfile, index) in employeeProfiles"
                        :value="employeeProfile.employee_id"
                        :key="index"
                        >{{ employeeProfile.employee_name_en }}|{{
                            employeeProfile.employee_name_kh
                        }}
                    </Option>
                </Select>
                <div class="invalid-feedback" v-if="errors.has('employee_id')">
                    {{ errors.first('employee_id') }}
                </div>
            </div>
            <div class="row mb-3">
                <label class="col-label form-label required tw-text-xs">{{
                    $t('basicSalary.salary')
                }}</label>
                <div class="tw-whitespace-nowrap">
                    <Poptip trigger="focus">
                        <Input
                            v-model.number="model.salary"
                            type="number"
                            :placeholder="$t('basicSalary.enterNumber')"
                            style="width:270%"
                            ref="salary"
                            :class="{
                                'is-invalid': errors.has('salary')
                            }"
                        >
                        </Input>
                        <div
                            class="invalid-feedback"
                            v-if="errors.has('salary')"
                        >
                            {{ errors.first('salary') }}
                        </div>
                        <div slot="content">
                            {{
                                model.salary
                                    ? formatNumber(model.salary)
                                    : $t('basicSalary.enterNumber')
                            }}
                        </div>
                    </Poptip>
                </div>
                <div class="invalid-feedback" v-if="errors.has('salary')">
                    {{ errors.first('salary') }}
                </div>
            </div>
            <div class="row mb-3">
                <label class="col-label form-label  tw-text-xs">{{
                    $t('basicSalary.salaryAfterProbation')
                }}</label>
                <div class="tw-whitespace-nowrap">
                    <Poptip trigger="focus">
                        <Input
                            v-model.number="model.salary_after_probation"
                            type="number"
                            :placeholder="$t('basicSalary.enterNumber')"
                            style="width:270%"
                            ref="salary_after_probation"
                            :class="{
                                'is-invalid': errors.has(
                                    'salary_after_probation'
                                )
                            }"
                        >
                        </Input>
                        <div
                            class="invalid-feedback"
                            v-if="errors.has('salary_after_probation')"
                        >
                            {{ errors.first('salary_after_probation') }}
                        </div>
                        <div slot="content">
                            {{
                                model.salary_after_probation
                                    ? formatNumber(model.salary_after_probation)
                                    : $t('basicSalary.enterNumber')
                            }}
                        </div>
                    </Poptip>
                </div>
            </div>
            <div class="row mb-3">
                <label class="col-label form-label required tw-text-xs">{{
                    $t('basicSalary.effectiveDate')
                }}</label>
                <div>
                    <date-Picker
                        :value="model.effective_date"
                        type="date"
                        format="dd-MM-yyyy"
                        placeholder="Select date"
                        style="width: 487px"
                        @on-change="date => (model.effective_date = date)"
                        :class="{
                            'is-invalid': errors.has('effective_date')
                        }"
                    ></date-Picker>
                    <div
                        class="invalid-feedback"
                        v-if="errors.has('effective_date')"
                    >
                        {{ errors.first('effective_date') }}
                    </div>
                </div>
            </div>
            <div class="row">
                <Checkbox v-model="model.payroll_visible">{{
                    $t('basicSalary.payrollVisible')
                }}</Checkbox>
            </div>
            <div slot="footer">
                <div class="tw-flex tw-justify-end tw-space-x-2 tw-sh mt-3">
                    <ts-button
                        @click.prevent="() => $emit('cancel')"
                        class="btn-gray"
                    >
                        {{ $t('cancel') }}</ts-button
                    >
                    <ts-button
                        v-if="!isUpdate"
                        color="primary"
                        outline
                        :waiting="waiting_new"
                        :disabled="waiting_new || waiting"
                        @click.prevent="onSaveAddNew"
                    >
                        {{ $t('saveAddNew') }}</ts-button
                    >
                    <ts-button
                        v-if="!isUpdate"
                        color="primary"
                        :waiting="waiting"
                        :disabled="waiting_new || waiting"
                        @click.prevent="onSave"
                    >
                        {{ $t('save') }}</ts-button
                    >
                    <ts-button
                        v-if="isUpdate"
                        color="primary"
                        @click.prevent="onUpdate"
                        :waiting="waiting"
                    >
                        {{ $t('update') }}</ts-button
                    >
                </div>
            </div>
        </div>
        <Spin fix v-if="loading"></Spin>
    </div>
</template>
<script>
import { Errors } from 'form-backend-validation'
import { mapActions, mapState, mapGetters } from 'vuex'
import { debounce, isEmpty } from 'lodash'
export default {
    name: 'basic-salary-form',
    data () {
        return {
            errors: new Errors(),
            loading: false,
            waiting: false,
            waiting_new: false,
            model: {
                employee_id: null,
                salary: null,
                salary_after_probation: null,
                effective_date: null,
                payroll_visible: true
            }
        }
    },
    computed: {
        ...mapState('humanResource/basicSalary', [
            'edit_data',
            'employeeProfiles'
        ]),
        ...mapGetters(['formatNumber']),
        isUpdate () {
            return !isEmpty(this.edit_data)
        }
        // employeeProfiles () {
        //     return this.$store.state.humanResource.basicSalary.employeeProfiles
        // }
    },
    methods: {
        ...mapActions('humanResource/basicSalary', ['getEmployeeProfile']),
        fetchResource () {
            this.loading = true
            this.getEmployeeProfile()
                .then(() => {})
                .catch(error => {
                    this.notice({ type: 'error', text: error.message })
                })
                .finally(() => {
                    this.loading = false
                })
        },
        loadEmployee: debounce(function (query) {
            this.getEmployeeProfile({ search: query })
        }, 1000),
        async onSave () {
            this.errors = new Errors()
            this.waiting = true
            this.$store
                .dispatch('humanResource/basicSalary/store', this.model)
                .then(response => {
                    this.notice({
                        type: response.message_id == 0 ? 'success' : 'warning',
                        text: response.message
                    })
                    this.$emit('cancel')
                    this.$emit('fetchData')
                })
                .catch(error => {
                    this.errors = new Errors(error.errors)
                    this.notice({ type: 'error', text: error.message })
                })
                .finally(() => {
                    this.waiting = false
                })
        },
        async onSaveAddNew () {
            this.errors = new Errors()
            this.waiting_new = true
            this.$store
                .dispatch(
                    'humanResource/basicSalary/store',
                    Object.assign({}, this.model)
                )
                .then(response => {
                    this.notice({
                        type: response.message_id == 0 ? 'success' : 'warning',
                        text: response.message
                    })
                    this.$emit('fetchData')
                    this.clearInput()
                    this.fetchResource()
                })
                .catch(error => {
                    this.errors = new Errors(error.errors)
                    this.notice({ type: 'error', text: error.message })
                })
                .finally(() => {
                    this.waiting_new = false
                })
        },
        onUpdate () {
            this.waiting = true
            this.$store
                .dispatch('humanResource/basicSalary/update', {
                    id: this.edit_data.id,
                    data: this.model
                })
                .then(response => {
                    this.notice({
                        type: response.message_id == 0 ? 'success' : 'warning',
                        text: response.message
                    })
                    this.$emit('cancel')
                    this.$emit('fetchData')
                    this.clearInput()
                })
                .catch(error => {
                    this.errors = new Errors(error.errors)
                    this.notice({ type: 'error', text: error.message })
                })
                .finally(() => {
                    this.waiting = false
                })
        },
        async setEditData () {
            if (!isEmpty(this.edit_data)) {
                await this.getEmployeeProfile({
                    search: this.edit_data.employee_profile.employee_name_en
                })
                this.model.employee_id = this.edit_data.employee_id
                this.model.salary = this.edit_data.salary
                this.model.salary_after_probation = this.edit_data.salary_after_probation
                this.model.effective_date = this.edit_data.effective_date
                this.model.payroll_visible = this.edit_data.payroll_visible
            }
        },
        async clearInput () {
            this.errors = new Errors()
            this.model.employee_id = []
            this.model.salary = null
            this.model.salary_after_probation = null
            this.model.effective_date = null
            this.model.payroll_visible = true
        },
        notice (not) {
            this.$Notice[not.type]({
                title: 'BASIC SALARY',
                desc: not.text
            })
        }
    },
    watch: {
        search: debounce(function (search) {
            this.fetchData({ search })
        }, 500)
    }
}
</script>
